<template>
  <section class="w-full">
    <vx-card>
          <b-card-title>دریافت جمع در آمد سرویس ها</b-card-title>
          <vs-divider />
          <div style="width: 100%" class="mt-5 flex justify-center items-center">
            <div class="mr-5 mt-4">
              <label>انتخاب تاریخ :</label>
              <br />
              <br />
              <date-picker-fa v-model="date" class="inputx" placeholder="بازه زمانی خود را انتخاب کنید" locale="en,fa" format="YYYY-MM"/>
            </div>
            <div class="mr-5 mt-5">
              <vs-input v-model="email" label="آدرس ایمیل" placeholder="آدرس ایمیل خود را وارد کنید" type="email" />
            </div>
            <div>
              <vs-select
                class="selectExample mt-5"
                label="انتخاب محصول"
                v-model="select3"
              >
                <vs-select-item :key="index" :value="item.value" :text="item.text" v-for="(item,index) in options3" />
              </vs-select>
            </div>
          </div>
      <br />
      <div class="flex justify-center mt-5">
        <vs-button @click="handelReort">دریافت خروجی</vs-button>
      </div>
    </vx-card>
  </section>
</template>

<script>

export default {
  name: 'PaymentIncome',
  components: {
  },
  data() {
    return {
      date: '',
      email: '',
      select3: 'HaioMail',
      options3:[
        {text: 'هایو میل', value: 'HaioMail'},
        {text: 'ویرا ابر', value: 'ViraAbr'},
        {text: ' هایو ابر', value: 'haiocloud'},
      ],
    }
  },
  methods: {
    handelReort () {
      let data = {
        date: this.date.toString(),
        email: this.email,
        product: this.select3
      }
      console.log('dara', data);
      this.$http.post('/admin/handledataexexp', {
        date: this.date.toString(),
        email: this.email,
        product: this.select3
      }).then((res) => {
        console.log('res create', res);
      }).catch((err) => {
        console.log('err', err);
      })
    }
  }
}
</script>

<style scoped>

</style>
